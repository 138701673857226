<template>
  <el-form ref="form" :model="dataForm">
    <el-form-item
        v-for="(item, index) in dataForm.list"
        :key="index"
        :label="'push prompt' +(index + 1) + ': '"
        :prop="'list.' + index + '.prompt'"
        :rules="{
            required: true, message: 'prompt不能为空', trigger: 'blur'
         }">
      <el-input style="width: 40%" type="textarea" v-model="item.prompt"></el-input>
      <el-button v-if="dataForm.list.length > 1" type="danger"  @click.prevent="removePrompt(item)">删除</el-button>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm">提交</el-button>
      <el-button @click="addPrompt" type="success" :disabled="dataForm.list.length >= 20">继续添加</el-button>
<!--      <el-button type="info" @click="getAll">刷新</el-button>-->
    </el-form-item>
  </el-form>
</template>

<script>

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        list: []
      }
    }
  },
  async created() {
    this.getAll();
  },
  methods: {
    getAll(){
      this.$http({
        url: this.$http.adornUrl(`/admin/officialPushTask/list`),
        method: 'get'
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataForm.list = data.result
        } else {
          this.$message.error(data.msg);
        }
      }).catch((err)=>{
        console.error(err)
        this.$message.error(err);
      })
    },
    requestSubmit() {
      this.$http({
        url: this.$http.adornUrl(`/admin/officialPushTask/save`),
        method: 'post',
        data: this.dataForm.list
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.visible = false
            }
          })
          this.getAll();
        } else {
          this.$message.error(data.msg)
        }
      }).catch((err)=>{
        console.error(err)
        this.$message.error(err);
      })
    },
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if(valid){
          this.$confirm('本次调整将影响角色的全站反馈影响较大，当前修改是否已确认无误？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.requestSubmit();
          })
        }
      })
    },
    removePrompt(item) {
      const index = this.dataForm.list.indexOf(item)
      if (index !== -1) {
        this.dataForm.list.splice(index, 1)
      }
    },
    addPrompt() {
      this.dataForm.list.push({
        prompt: ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>